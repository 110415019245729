<template>
  <div class="logo-animation-container">
    <div class="logo">
      <img src="../../assets/playvillLogo.png" />
    </div>
    <div class="shadow"></div>
  </div>
</template>

<style scoped>
.logo-animation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo {
  z-index: 1;
}

.logo img {
  width: 12rem;
  height: auto;
}

.shadow {
  height: 1.5rem;
  width: 10rem;
  background-color: green;
  margin-top: -1rem;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
}
</style>

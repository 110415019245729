<template>
  <div
    class="quick-games-list-container"
    :style="{ height: listHeightStyle, 'max-height': listHeightStyle }"
  >
    <div class="title"><h2>Featured Games</h2></div>
    <div class="game-list">
      <router-link
        v-for="(game, index) in games"
        :key="game.id"
        :to="game.route"
        class="router-link"
        custom
        v-slot="{ navigate }"
      >
        <div @click="navigate">
          <quick-game-tile
            :imageSrc="game.imageSrc"
            :title="game.title"
            :category="game.category"
          ></quick-game-tile>
          <!-- Conditionally render the divider div only if it's not the last item -->
          <div v-if="index < games.length - 1" class="divider"></div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import QuickGameTile from "./QuickGameTile.vue";
import cityBuilder from "../../assets/cityBuilder.jpg";
import emojiCrushed from "../../assets/emojiCrushed.jpg";
import katanaFruits from "../../assets/katanaFruits.jpg";

export default {
  components: {
    QuickGameTile,
  },
  props: {
    quickLinksHeight: Number,
  },
  data() {
    return {
      isFullHeight: false,
      games: [
        {
          id: 1,
          title: "City Builder",
          category: "Casual",
          imageSrc: cityBuilder,
          route: "/games/city-builder",
        },
        {
          id: 2,
          title: "Emoji Crushed",
          category: "Puzzle",
          imageSrc: emojiCrushed,
          route: "/games/emoji-crushed",
        },
        {
          id: 3,
          title: "Katana Fruits",
          category: "Casual",
          imageSrc: katanaFruits,
          route: "/games/katana-fruits",
        },
      ],
    };
  },
  computed: {
    listHeightStyle() {
      if (this.isFullHeight) {
        return "100vh";
      }
      return `calc(${this.quickLinksHeight}px)`;
    },
  },
};
</script>

<style scoped>
.quick-games-list-container {
  background-color: var(--vt-c-white);
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  overflow-y: auto;
}

.game-list {
  padding: 0rem 1rem 15rem 1rem;
}

.title {
  margin: 0rem 1rem 0rem 1rem;
}

.router-link {
  text-decoration: none;
  color: inherit;
}

.divider {
  margin: 1rem;
  height: 0.1rem;
  background-color: var(--vt-c-divider-dark-2);
}
</style>

<template>
  <div class="all-games-container">
    <div class="competition-banner">
      <img src="../assets/banner.jpg" alt="Playville Competition" />
    </div>
    <div class="game-of-the-day">
      <h3 class="title">Game of the day</h3>
      <div class="base-card">
        <router-link :to="gameOfTheDay.route" class="router-link">
          <quick-game-tile
            :imageSrc="gameOfTheDay.imageSrc"
            :title="gameOfTheDay.title"
            :category="gameOfTheDay.category"
            titleColor="#ffffff"
            categoryColor="#DDDDDD"
            iconColor="#ffffff"
          ></quick-game-tile>
        </router-link>
      </div>
    </div>
    <div class="featured-games-section">
      <h3 class="title">Featured games</h3>
      <div class="featured-games">
        <router-link
          v-for="(game, index) in games"
          :key="game.id"
          :to="game.route"
          class="router-link"
          custom
          v-slot="{ navigate }"
        >
          <div @click="navigate">
            <game-feature
              :imageSrc="game.imageSrc"
              :title="game.title"
              class="game-feature"
            ></game-feature>
            <!-- Conditionally render the divider div only if it's not the last item -->
            <div v-if="index < games.length - 1" class="divider"></div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import QuickGameTile from "../components/games/QuickGameTile.vue";
import GameFeature from "../components/games/GameFeature.vue";
import cityBuilder from "../assets/cityBuilder.jpg";
import emojiCrushed from "../assets/emojiCrushed.jpg";
import katanaFruits from "../assets/katanaFruits.jpg";

export default {
  components: {
    QuickGameTile,
    GameFeature,
  },
  data() {
    return {
      gameOfTheDay: {
        title: "City Builder",
        category: "Casual",
        imageSrc: cityBuilder,
        route: "/games/city-builder",
      },
      games: [
        {
          id: 1,
          title: "City Builder",
          imageSrc: cityBuilder,
          route: "/games/city-builder",
        },
        {
          id: 2,
          title: "Emoji Crush",
          imageSrc: emojiCrushed,
          route: "/games/emoji-crushed",
        },
        {
          id: 3,
          title: "Katana Fruits",
          imageSrc: katanaFruits,
          route: "/games/katana-fruits",
        },
      ],
    };
  },
};
</script>

<style scoped>
.competition-banner img {
  width: 100%;
  height: 13rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  object-fit: cover;
}

.title {
  color: var(--color-heading);
  margin: 1rem 1rem 0rem 1rem;
}

.base-card {
  background-color: #4550c4;
  margin: 1rem 1rem 0rem 1rem;
  border-radius: 1rem;
  padding: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.router-link {
  text-decoration: none;
  color: inherit;
}

.featured-games {
  display: flex;
  align-items: center;
  overflow-x: auto;
  margin: 0.7rem 1rem 0rem 1rem;
  padding: 0.3rem 0rem 0.3rem 0rem;
}

.featured-games::-webkit-scrollbar {
  display: none;
}

.game-feature:not(:last-child) {
  margin-right: 1rem;
}
</style>

<template>
  <div class="token-handler-view">
    <div class="spinner-container">
      <div class="spinner">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
      <p class="slogan">Get playing</p>
    </div>
  </div>
</template>

<style scoped>
.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: var(--color-background);
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20rem;
  margin-bottom: 0.5rem;
}

.dot {
  width: 0.5rem;
  height: 0.5rem;
  background-color: var(--color-heading);
  border-radius: 50%;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: calc(0.125s * var(--i));
  margin: 0.5rem;
}

.dot:nth-child(1) {
  --i: 1;
}
.dot:nth-child(2) {
  --i: 2;
}
.dot:nth-child(3) {
  --i: 3;
}
.dot:nth-child(4) {
  --i: 4;
}
.dot:nth-child(5) {
  --i: 5;
}
.dot:nth-child(6) {
  --i: 6;
}
.dot:nth-child(7) {
  --i: 7;
}
.dot:nth-child(8) {
  --i: 8;
}

@keyframes dotFlashing {
  0%,
  100% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
}

.slogan {
  font-weight: bold;
  color: var(--color-heading);
}

.error-message {
  text-align: center;
  color: var(--color-heading);
  margin-top: 1rem;
}
</style>

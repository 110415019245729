<template>
  <div class="quick-game-tile-container">
    <div class="game-summary">
      <div class="image-field">
        <img :src="imageSrc" :alt="title" class="game-image" />
      </div>
      <div class="game-details">
        <!-- Bind style for titleColor -->
        <span class="game-title" :style="{ color: titleColor }">{{
          title
        }}</span>
        <!-- Bind style for categoryColor -->
        <span class="game-category" :style="{ color: categoryColor }">{{
          category
        }}</span>
      </div>
    </div>
    <div class="play-now">
      <!-- Bind class for dynamic icon color -->
      <font-awesome-icon
        :icon="['fa-solid', 'fa-play']"
        class="play-now-icon"
        :style="{ color: iconColor }"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imageSrc: String,
    title: String,
    category: String,
    titleColor: String, // new prop for title color
    categoryColor: String, // new prop for category color
    iconColor: String, // new prop for icon color
  },
};
</script>

<style scoped>
.quick-game-tile-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.game-summary {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-field {
  border-radius: 1rem;
  height: 5rem;
  width: 5rem;
}

.game-image {
  border-radius: 1rem;
  height: 5rem;
  width: 5rem;
}

.game-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  justify-self: left;
  margin-left: 1rem;
}

.game-title {
  color: var(--color-text);
  font-weight: bold;
  font-size: 1.2rem;
}

.game-category {
  color: var(--vt-c-text-light-2);
}

.play-now-icon {
  color: var(--vt-c-purple-primary);
}
</style>

<template>
  <div class="quick-links-container">
    <div class="items-container">
      <div class="link" @click="toggleFavouriteModal">
        <div class="icon-container icon-container-1">
          <font-awesome-icon icon="fa-solid fa-star" />
        </div>
        <p class="category">Favourites</p>
      </div>
      <div class="link" @click="toggleLeaderboardModal">
        <div class="icon-container icon-container-2">
          <font-awesome-icon icon="fa-solid fa-trophy" />
        </div>
        <p class="category">Leaderboard</p>
      </div>
    </div>
  </div>
  <base-card
    :isVisible="isFavouriteModalVisible"
    @click.self="toggleFavouriteModal(false)"
    @close="toggleFavouriteModal(false)"
  >
    <router-link :to="favouriteGame.route" class="router-link">
      <quick-game-tile
        :imageSrc="favouriteGame.imageSrc"
        :title="favouriteGame.title"
        :category="favouriteGame.category"
      ></quick-game-tile> </router-link
  ></base-card>
  <base-card
    :isVisible="isleaderboardModalVisible"
    @click.self="toggleLeaderboardModal(false)"
    @close="toggleLeaderboardModal(false)"
  >
    <game-leaderboard></game-leaderboard>
  </base-card>
</template>

<script>
import BaseCard from "../ui/BaseCard.vue";
import QuickGameTile from "../games/QuickGameTile.vue";
import GameLeaderboard from "../scores/GameLeaderboard.vue";
import cityBuilder from "../../assets/cityBuilder.jpg";

export default {
  components: {
    BaseCard,
    QuickGameTile,
    GameLeaderboard,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      isFavouriteModalVisible: false,
      isleaderboardModalVisible: false,
      favouriteGame: {
        title: "City Builder",
        category: "Casual",
        imageSrc: cityBuilder,
        route: "/games/city-builder",
      },
    };
  },
  methods: {
    toggleFavouriteModal(value = null) {
      this.isFavouriteModalVisible =
        value !== null ? value : !this.isFavouriteModalVisible;

      if (this.isFavouriteModalVisible) {
        document.body.style.overflow = "hidden"; // Prevent scrolling when modal is open
      } else {
        document.body.style.overflow = ""; // Re-enable scrolling when modal is closed
      }
    },
    toggleLeaderboardModal(value = null) {
      this.isleaderboardModalVisible =
        value !== null ? value : !this.isleaderboardModalVisible;

      if (this.isleaderboardModalVisible) {
        document.body.style.overflow = "hidden"; // Prevent scrolling when modal is open
      } else {
        document.body.style.overflow = ""; // Re-enable scrolling when modal is closed
      }
    },
  },
};
</script>

<style scoped>
.quick-links-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.items-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: var(--vt-c-white);
  padding: 0rem 1rem 0rem 1rem;
  border-radius: 50rem;
  gap: 1.5rem;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  margin-right: 0.4rem;
  border-radius: 50rem;
  color: var(--vt-c-white);
  font-size: 1rem;
}

.icon-container-1 {
  background-color: var(--vt-c-blue-primary);
}

.icon-container-2 {
  background-color: var(--vt-c-purple-primary);
}

.category {
  font-size: 0.8rem;
  color: var(--color-text);
}

.router-link {
  text-decoration: none;
  color: inherit;
}
</style>

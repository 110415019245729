<template>
  <div class="modal-backdrop" v-show="isVisible">
    <div class="base-card" @click.stop>
      <div class="close-btn-wrapper" @click="$emit('close')">
        <button class="close-btn" @click="$emit('close')">&times;</button>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseCard",
  props: {
    isVisible: Boolean,
  },
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  overflow: visible;
}

.base-card {
  position: relative;
  width: 80%;
  border-radius: 0.5rem;
  background-color: white;
  padding: 1rem;
  z-index: 1001;
  max-height: 20rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.close-btn-wrapper {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: flex-end;
  background-color: var(--color-heading);
  border-radius: 50rem;
  width: 1.5rem;
  height: 1.5rem;
  border: solid 0.2rem var(--vt-c-purple-primary);
  z-index: 2000;
}

.close-btn {
  background: none;
  border: none;
  font-size: 24px; /* Adjust as needed */
  cursor: pointer;
  font-size: 1rem;
  color: var(--vt-c-purple-primary);
  line-height: 1;
}
</style>

<template>
  <div class="game-container">
    <iframe
      class="game-iframe"
      :src="gameUrl"
      frameborder="0"
      scrolling="no"
      @load="handleIframeLoad"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "CityBuilder",
  data() {
    return {
      gameUrl: `/katanaFruits/index.html`, // Initial URL to your game
      loadedOnce: false,
    };
  },
  methods: {
    handleIframeLoad() {
      if (!this.loadedOnce) {
        // Clear localStorage and sessionStorage here
        localStorage.clear();
        sessionStorage.clear();

        // Force iframe to reload with a cache-busting query parameter
        this.gameUrl = `/katanaFruits/index.html?nocache=${new Date().getTime()}`;
        this.loadedOnce = true; // Ensure we only do this once
      }
    },
  },
};
</script>

<style>
html,
body {
  height: 100%;
  margin: 0;
}
.game-container {
  height: calc(100vh - 2rem);
  width: 100%;
  overflow: hidden;
}
.game-iframe {
  width: 100%;
  height: 100%;
}
</style>

<template>
  <div class="token-handler-view">
    <div v-if="loading" class="spinner-container">
      <div class="spinner">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
      <p class="slogan">Get playing</p>
    </div>
    <div v-if="error" class="error-message">
      Failed to process the token. Please try again.
    </div>
  </div>
</template>

<script>
// Import your service and store as you have them
import PlayvillService from "../services/playvillService.js";
import store from "../store/index.js";

export default {
  name: "TokenHandlerView",
  data() {
    return {
      loading: true,
      error: false,
    };
  },
  mounted() {
    this.processToken();
  },
  methods: {
    async processToken() {
      const encodedToken = this.$route.query.token;

      const token = decodeURIComponent(encodedToken);

      try {
        const response = await PlayvillService.decryptLoginToken(token);
        const { authenticated, msisdn, username } = response.data;

        if (authenticated === 1) {
          await store.dispatch("login", {
            phoneNumber: msisdn,
            username: username,
          });
          this.$router.push({ name: "home" });
        } else {
          this.$router.push({ name: "login" });
        }
      } catch (error) {
        console.error("Error processing token:", error);
        this.error = true;
        this.$router.push({ name: "login" });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20rem;
  margin-bottom: 0.5rem;
}

.dot {
  width: 0.5rem;
  height: 0.5rem;
  background-color: var(--color-heading);
  border-radius: 50%;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: calc(0.125s * var(--i));
  margin: 0.5rem;
}

.dot:nth-child(1) {
  --i: 1;
}
.dot:nth-child(2) {
  --i: 2;
}
.dot:nth-child(3) {
  --i: 3;
}
.dot:nth-child(4) {
  --i: 4;
}
.dot:nth-child(5) {
  --i: 5;
}
.dot:nth-child(6) {
  --i: 6;
}
.dot:nth-child(7) {
  --i: 7;
}
.dot:nth-child(8) {
  --i: 8;
}

@keyframes dotFlashing {
  0%,
  100% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
}

.slogan {
  font-weight: bold;
  color: var(--color-heading);
}

.error-message {
  text-align: center;
  color: var(--color-heading);
  margin-top: 1rem;
}
</style>

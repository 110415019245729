<template>
  <div class="display-username-container">
    <router-link to="/settings" class="username-wrapper">
      <p class="username">{{ username }}</p>
      <font-awesome-icon icon="fa-solid fa-pencil" class="edit" />
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["username"]),
  },
};
</script>

<style scoped>
.username-wrapper {
  display: inline-flex;
  align-items: center;
  padding: 0.3rem 1rem;
  border-radius: 50rem;
  background-color: var(--vt-c-white);
  border: solid 0.2rem blue;
  text-decoration: none;
  color: inherit;
}

.username {
  padding: 0;
  margin: 0;
  font-size: 0.8rem;
}

.edit {
  margin-left: 0.5rem;
  font-size: 0.8rem;
  color: var(--vt-c-text-light-2);
}
</style>

<template>
  <loading-spinner v-if="loading" class="loading-spinner"></loading-spinner>
  <div class="competition-banner">
    <img src="../assets/banner.jpg" alt="Playville Competition" />
  </div>
  <div class="login-view-container">
    <div class="login-wrapper">
      <div class="login-heading">Login to play</div>
      <div class="msisdn">
        <input
          type="tel"
          v-model="phoneNumber"
          @input="handleInput"
          @keypress.enter="handleEnter"
          @blur="scrollToTop"
          placeholder="Enter your cell number"
          class="input-field"
        />
      </div>
      <button @click="handleLogin" class="start-playing-button">
        Get playing
      </button>
      <p v-if="showError" class="error-message">
        Please enter a valid South African cell number.
      </p>
    </div>
  </div>
  <div v-if="!loading" class="login-view-image">
    <img src="../assets/loginViewImage.png" alt="Playville Competition" />
  </div>
</template>

<script>
import store from "../store";
import PlayvillService from "../services/playvillService.js";
import LoadingSpinner from "../components/ui/LoadingSpinner.vue";

export default {
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      phoneNumber: "",
      showError: false,
      loading: false,
    };
  },
  mounted() {
    document.body.style.overflow = "hidden"; // Prevent scrolling when component is mounted
  },
  beforeUnmount() {
    document.body.style.overflow = ""; // Re-enable scrolling when component is unmounted
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    handleInput() {
      this.showError = false;
    },
    handleEnter(event) {
      if (event.key === "Enter") {
        this.handleLogin();
      }
    },
    formatPhoneNumber() {
      this.phoneNumber = this.phoneNumber.replace(/\D/g, "");

      if (this.phoneNumber.length > 11) {
        this.phoneNumber = this.phoneNumber.slice(0, 11);
      }
    },
    async handleLogin() {
      this.scrollToTop();
      if (this.isValidSouthAfricanNumber()) {
        this.loading = true;
        if (this.phoneNumber.length === 10) {
          this.phoneNumber = "27" + this.phoneNumber.substring(1);
        }
        const response = await PlayvillService.checkUser(
          this.phoneNumber,
          "Playvill"
        );
        if (response.data.authenticated === 1) {
          await store.dispatch("login", {
            phoneNumber: this.phoneNumber,
            username: response.data.username,
          });
          this.$router.push({ name: "home" });
        } else if (response.data.authenticated === 0) {
          window.location.href = response.data.urlRedirect;
          // window.open(response.data.urlRedirect, "_blank");
        } else {
          console.log("Authentication failed!");
        }
      } else {
        this.showError = true;
        this.loading = false;
      }

      this.phoneNumber = "";
      this.loading = false;
    },
    isValidSouthAfricanNumber() {
      const saNumberRegex = /^(0|27)\d{9}$/;
      return saNumberRegex.test(this.phoneNumber);
    },
  },
};
</script>

<style scoped>
.competition-banner {
  position: absolute;
  display: inline-block; /* or 'block' depending on your layout */
  width: 100%;
  height: 13rem;
  overflow: hidden; /* Ensures the pseudo-element doesn't extend beyond this container */
}

.competition-banner img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.competition-banner::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20%; /* Adjust the height to control the fade effect size */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #4964da);
  /* Replace rgba(255,255,255,1) with the background color of your container if it's not white */
}

.login-view-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--vt-c-blue-primary);
  border: solid 0.5rem var(--vt-c-purple-primary);
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
  z-index: 500;
}

.login-heading {
  background-color: #cc66c9;
  padding: 0.7rem 1.3rem;
  margin-top: -2.9rem;
  color: var(--color-heading);
  border: solid 0.3rem var(--color-heading);
  border-radius: 50rem;
  font-family: "Russo One";
}

.msisdn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 1.5rem;
}

.input-field {
  width: 13rem;
  padding: 0.75rem;
  border-radius: 0.5rem;
  border: none;
  background-color: var(--vt-c-white);
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
}

.input-field:focus {
  outline: none;
  font-family: "Montserrat", sans-serif;
  background-color: var(--vt-c-white);
  color: var(--color-text);
  font-size: 1rem;
}

.start-playing-button {
  width: 14.5rem;
  padding: 0.75rem;
  border-radius: 0.5rem;
  /* Smooth gradient background */
  background-image: linear-gradient(to bottom right, #008000, #00ff00);
  color: var(--color-heading);
  border: solid 0.3rem var(--color-heading);
  cursor: pointer;
  font-family: "Russo One", sans-serif; /* Added fallback font */
  margin-top: 1rem;
  font-size: 1rem;
  font-style: italic;
}

.error-message {
  color: var(--color-heading);
  font-size: 0.8rem;
  margin-top: 1rem;
  max-width: 13rem;
  text-align: center;
}

.login-view-image {
  position: absolute;
  bottom: 0;
  width: 100%;
  overflow: hidden;
}

.login-view-image img {
  object-fit: cover;
  width: 100%;
  display: block;
}

.loading-spinner {
  z-index: 2000;
  width: 100%;
  height: 100vh;
}
</style>

<template>
  <nav class="navbar">
    <ul class="nav-list">
      <li
        class="nav-item"
        :class="{ active: isActive('/games') }"
        @click="navigate('games')"
      >
        <font-awesome-icon icon="fa-solid fa-gamepad" />
      </li>
      <li
        class="nav-item"
        :class="{ active: isActive('/landing') }"
        @click="navigate('home')"
      >
        <font-awesome-icon icon="fa-solid fa-house" />
      </li>
      <li
        class="nav-item"
        :class="{ active: isActive('/settings') }"
        @click="navigate('settings')"
      >
        <font-awesome-icon icon="fa-solid fa-gear" />
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  methods: {
    navigate(routeName) {
      this.$router.push({ name: routeName });
    },
    isActive(routePath) {
      // Check if the current path includes the routePath
      return this.$route.path === routePath;
    },
  },
};
</script>

<style scoped>
.navbar {
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: rgba(235, 235, 235);
  display: flex;
  justify-content: space-around;
  padding: 1rem 0;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: var(--vt-c-divider-dark-1);
  font-size: 1rem;
}

.nav-item.active {
  color: var(--vt-c-blue-primary);
}

.nav-item:focus,
.nav-item:active,
.nav-item:focus .font-awesome-icon,
.nav-item:active .font-awesome-icon {
  outline: none !important;
  border: none !important;
  box-shadow: none !important; /* In case the outline is caused by a box-shadow */
}

.nav-item,
.font-awesome-icon {
  -webkit-tap-highlight-color: transparent;
}

.nav-item:focus-within {
  outline: none !important;
}

/* This is to ensure icons align properly if FontAwesome is used */
.font-awesome-icon {
  display: flex;
  align-items: center;
}
</style>

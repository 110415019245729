import { createRouter, createWebHistory } from "vue-router";
import store from "../store/index.js";

import HomeView from "../views/HomeView.vue";
import CityBuilder from "../views/CityBuilder.vue";
import AllGames from "../views/AllGames.vue";
import EmojiCrushed from "../views/EmojiCrushed.vue";
import KatanaFruits from "../views/KatanaFruits.vue";
import SettingsView from "../views/SettingsView.vue";
import LoginView from "../views/LoginView.vue";
import TermsView from "../views/TermsView.vue";
import TokenHandlerView from "../views/TokenHandlerView.vue";
import ManageSubscriptionView from "../views/ManageSubscriptionView.vue";

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes: [
    { path: "/", redirect: "/login" },
    {
      path: "/login",
      name: "login",
      component: LoginView,
      meta: { hideNavbar: true },
    },
    { path: "/landing", name: "home", component: HomeView },
    { path: "/games", name: "games" },
    { path: "/settings", name: "settings", component: SettingsView },
    {
      path: "/terms",
      name: "terms",
      component: TermsView,
    },
    {
      path: "/manage-subscription",
      name: "manageSubscription",
      component: ManageSubscriptionView,
    },
    { path: "/games", name: "games", component: AllGames },
    {
      path: "/games/city-builder",
      name: "cityBuilder",
      component: CityBuilder,
    },
    {
      path: "/games/emoji-crushed",
      name: "emojiCrushed",
      component: EmojiCrushed,
    },
    {
      path: "/games/katana-fruits",
      name: "katanaFruits",
      component: KatanaFruits,
    },
    {
      path: "/token",
      name: "tokenHandler",
      component: TokenHandlerView, // You'll need to create this component
      beforeEnter: (to, from, next) => {
        // Optional: Add logic here to verify the token format before proceeding
        next();
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);

  if (
    to.name === "login" ||
    to.path === "/terms" ||
    to.name === "tokenHandler"
  ) {
    next();
  } else if (!store.getters.isAuthenticated) {
    next({ name: "login" });
  } else {
    next();
  }
});

export default router;

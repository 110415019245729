<template>
  <div class="username-edit">
    <input
      v-if="isEditing"
      v-model="editableUsername"
      @input="checkUsernameLength"
      @keyup.enter="saveUsername"
      @blur="saveUsername"
      ref="usernameInput"
    />
    <!-- Use the Vuex getter for displaying the username -->
    <span v-else @click="editUsername">{{ username }}</span>
    <button v-if="!isEditing" @click="editUsername" class="btn">
      <font-awesome-icon icon="fa-solid fa-pencil" class="edit" />
    </button>
  </div>
  <p v-if="showError" class="error-message">
    Usernames are limited to 15 characters
  </p>
</template>

<script>
import { mapGetters } from "vuex";
import PlayvillService from "../../services/playvillService.js";

export default {
  data() {
    return {
      isEditing: false,
      editableUsername: "",
      showError: false,
    };
  },
  computed: {
    ...mapGetters(["msisdn", "username"]),
  },
  methods: {
    editUsername() {
      this.editableUsername = this.username;
      this.isEditing = true;
      this.$nextTick(() => {
        this.$refs.usernameInput.focus();
      });
    },
    async saveUsername() {
      this.$store.dispatch("updateUsername", this.editableUsername);
      this.isEditing = false;
      await PlayvillService.updateUsername(
        this.msisdn,
        "Playvill",
        this.username
      );
    },
    checkUsernameLength() {
      // Prevent the user from entering more than 20 characters
      this.editableUsername = this.editableUsername.replace(/\s+/g, "");
      if (this.editableUsername.length >= 15) {
        this.editableUsername = this.editableUsername.substr(0, 15);
        this.showError = true;
      } else {
        this.showError = false; // Clear the error message when the length is valid
      }
    },
  },
};
</script>

<style scoped>
.username-edit {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-heading);
  font-size: 1.5rem;
}

.username-edit input {
  margin-right: 0.5rem;
  background: transparent;
  border: none;
  font-family: "Montserrat", sans-serif;
}

.username-edit input:focus {
  background: transparent;
  border-color: transparent;
  border-bottom: 0.15rem solid #4550c4;
  box-shadow: none;
  outline: none;
  font-size: 1.5rem;
  color: var(--color-heading);
  font-family: "Montserrat", sans-serif;
}

.username-edit img {
  cursor: pointer;
}

.btn {
  background: none;
  border: none;
  padding: 0;
  margin-left: 1rem;
  cursor: pointer;
  outline: none;
  color: var(--color-heading);
}

.error-message {
  color: var(--color-heading);
  font-size: 0.8rem;
  margin-top: 1rem;
  text-align: center;
}
</style>

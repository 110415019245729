<template>
  <div class="manage-subsciption-view-container">
    <div class="sad-face">
      <img src="../assets/sadFace.png" />
    </div>
    <div class="unsubscribe">
      <h4>Thinking of leaving so soon?</h4>
      <h5>How to unsubscribe:</h5>
      <p>To unsubscribe dial *155#</p>
      <p class="leave-message">We'll be sad to say good bye</p>
    </div>
  </div>
</template>

<style scoped>
.manage-subscription-view-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sad-face {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  margin-top: 5rem;
}

.sad-face img {
  width: 10rem;
}

.unsubscribe {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.unsubscribe h4,
h5,
p {
  color: var(--color-heading);
}

.unsubscribe p {
  font-size: 0.8rem;
}

.leave-message {
  margin-top: 5rem;
}
</style>

<template>
  <div class="game-leaderboard-container">
    <div class="leaderboard-filter">
      <div class="dropdown" @click="toggleDropdown">
        <div class="dropdown-filter">
          <img
            :src="selectedGame.image"
            :alt="selectedGame.name"
            class="game-image"
          />
          <h4 class="selected-game">{{ selectedGame.name }}</h4>
          <font-awesome-icon
            icon="fa-solid fa-angle-down"
            v-if="!dropdownOpen"
            class="icon"
          />
          <font-awesome-icon
            icon="fa-solid fa-angle-up"
            v-if="dropdownOpen"
            class="icon"
          />
        </div>
        <div class="divider" v-if="dropdownOpen"></div>
        <div class="dropdown-content" v-if="dropdownOpen">
          <div
            v-for="(game, index) in games"
            :key="index"
            @click.stop="selectGame(game, $event)"
            class="dropdown-item"
          >
            <img :src="game.image" :alt="game.name" class="game-image" />
            <span>{{ game.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="leaderboard-wrapper">
      <div v-for="(user, index) in users" :key="user.id" class="user">
        <div class="details">
          <div :class="['position', getPositionClass(index)]">
            <div class="sub-position">{{ user.rank }}</div>
          </div>
          <p class="username">{{ user.name }}</p>
        </div>
        <p class="score">{{ user.score }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PlayvillService from "../../services/playvillService.js";
import cityBuilder from "../../assets/cityBuilder.jpg";
import emojiCrushed from "../../assets/emojiCrushed.jpg";
import katanaFruits from "../../assets/katanaFruits.jpg";

export default {
  data() {
    const defaultGame = { name: "City Builder", image: cityBuilder };
    return {
      dropdownOpen: false,
      selectedGame: defaultGame,
      games: [
        defaultGame,
        { name: "Emoji Crushed", image: emojiCrushed },
        { name: "Katana Fruits", image: katanaFruits },
      ],
      users: [],
    };
  },
  computed: {
    ...mapGetters([
      "msisdn", // Assuming 'msisdn' is the name of the getter in your store
    ]),
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    async selectGame(game, $event) {
      this.selectedGame = game;
      this.dropdownOpen = false;
      $event.stopPropagation();
      await this.getLeaderboard(game.name);
    },
    getPositionClass(index) {
      switch (index) {
        case 0:
          return "gold";
        case 1:
          return "silver";
        case 2:
          return "bronze";
        default:
          return "blue";
      }
    },
    async getLeaderboard(gameName) {
      try {
        const response = await PlayvillService.getLeaderboard(
          this.msisdn,
          "Playvill",
          gameName
        );
        if (response && response.data) {
          let tempUsers = response.data.recordset.map((user) => ({
            msisdn: user.Msisdn,
            name: user.Username,
            rank: user.Rank,
            score: user.Score,
          }));

          const loggedInUserId = this.msisdn;

          const ids = tempUsers.map((user) => user.msisdn);
          const lastIndex = ids.lastIndexOf(loggedInUserId);
          const firstIndex = ids.indexOf(loggedInUserId);

          if (firstIndex !== -1 && firstIndex !== lastIndex) {
            tempUsers.pop();
          }

          this.users = tempUsers;
        }
      } catch (error) {
        console.error("Failed to fetch leaderboard:", error);
        // Handle error appropriately (e.g., showing an error message to the user)
      }
    },
  },
  mounted() {
    // Fetch default game leaderboard when the component is mounted
    this.getLeaderboard(this.selectedGame.name);
  },
};
</script>

<style scoped>
.dropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dropdown-filter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider {
  height: 0.1rem;
  width: 80%;
  background-color: var(--vt-c-divider-dark-2);
  margin-bottom: 1rem;
}

.leaderboard-divider {
  height: 0.1rem;
  width: 100%;
  background-color: var(--vt-c-divider-dark-2);
  margin-bottom: 1rem;
}

.icon {
  margin-left: 1rem;
}

.dropdown-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1rem;
}

.game-image {
  width: 2rem;
  height: 2rem;
  border-radius: 50rem;
  margin-right: 1rem;
}

.user {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.details {
  display: flex;
  align-items: center;
  justify-content: center;
}

.position {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: gold;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 0.5rem;
}

.sub-position {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 0.2rem var(--color-text);
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
}

.username {
  margin-left: 1rem;
}

.gold {
  background-color: gold;
}

.silver {
  background-color: silver;
}

.bronze {
  background-color: #cd7f32; /* A common bronze color */
}

.blue {
  background-color: #3498db; /* A nice shade of blue */
}
</style>

import axios from "axios";
import { environmentConfig } from "../config/environmentConfig";

export default class PlayvillService {
  static async upsertGame(msisdn, service, internalGame, score) {
    try {
      return await axios.post(
        `${environmentConfig.playvillService}/game-play/upsert-game`,
        {
          msisdn: msisdn,
          service: service,
          internalGame: internalGame,
          score: score,
        }
      );
    } catch (error) {
      console.error("There was an error!", error);
      // Handle the error as needed
    }
  }

  static async getLeaderboard(msisdn, service, internalGame) {
    try {
      return await axios.post(
        `${environmentConfig.playvillService}/points/leaderboard`,
        {
          msisdn: msisdn,
          service: service,
          internalGame: internalGame,
        }
      );
    } catch (error) {
      console.error("There was an error!", error);
      // Handle the error as needed
    }
  }

  static async decryptLoginToken(token) {
    try {
      return await axios.post(
        `${environmentConfig.playvillService}/decrypt-token`,
        {
          token: token,
        }
      );
    } catch (error) {
      console.error("There was an error!", error);
      // Handle the error as needed
    }
  }

  static async checkUser(msisdn, service) {
    try {
      const response = await axios.post(
        `${environmentConfig.playvillService}/web/check-user`,
        {
          msisdn: msisdn,
          service: service,
        }
      );
      return response;
    } catch (error) {
      console.error("There was an error!", error);
      // Handle the error as needed
    }
  }

  static async getUserDetails(msisdn, service) {
    try {
      return await axios.post(
        `${environmentConfig.playvillService}/user/get-user-details`,
        {
          msisdn: msisdn,
          service: service,
        }
      );
    } catch (error) {
      console.error("There was an error!", error);
      // Handle the error as needed
    }
  }

  static async updateUsername(msisdn, service, username) {
    try {
      return await axios.post(
        `${environmentConfig.playvillService}/user/update-username`,
        {
          msisdn: msisdn,
          service: service,
          username: username,
        }
      );
    } catch (error) {
      console.error("There was an error!", error);
      // Handle the error as needed
    }
  }
}

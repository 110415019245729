<template>
  <div class="app-container">
    <router-view></router-view>
    <nav-bar
      v-if="handleNavbar && !$route.meta.hideNavbar"
      class="nav-bar"
    ></nav-bar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PlayvillService from "./services/playvillService.js";
import NavBar from "./components/layout/NavBar.vue";
import store from "./store";

export default {
  components: {
    NavBar,
  },
  mounted() {
    window.addEventListener("message", this.handleGameMessages, false);
  },
  beforeUnmount() {
    window.removeEventListener("message", this.handleGameMessages, false);
  },
  computed: {
    ...mapGetters([
      "msisdn", // Assuming 'msisdn' is the name of the getter in your store
    ]),
    handleNavbar() {
      return store.getters.isAuthenticated;
    },
  },
  methods: {
    async handleGameMessages(event) {
      // Array of allowed origins
      const allowedOrigins = [
        "https://www.playvill.co.za",
        "https://playvill.co.za",
      ];

      // Check if the event.origin is in the array of allowed origins
      if (!allowedOrigins.includes(event.origin)) {
        console.warn("Message from unexpected origin:", event.origin);
        return;
      }

      // Your existing logic for handling the message
      if (event.data && event.data.type === "scoreUpdated") {
        await PlayvillService.upsertGame(
          this.msisdn,
          "Playvill",
          event.data.internalGame,
          event.data.points
        );
      }
    },
  },
};
</script>

<style>
@import "./assets/base.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Russo+One:wght@400;700&display=swap");

html {
  font-family: "Montserrat", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-family: "Montserrat", sans-serif;
  color: var(--color-text);
  background: var(--color-background);
  font-size: 16px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nav-bar {
  z-index: 10000;
}

.nav-bar {
  max-width: 480px;
}

.app-container {
  max-width: 480px; /* Limiting width to 480px */
  margin: 0 auto; /* Centering the container */
  position: relative;
}
</style>

import dotenv from "dotenv";

dotenv.config();

export const productionConfig = {
  playvillService: `${process.env.VUE_APP_PLAYVILL_SERVICE_PRODUCTION}`,
};

export const developmentConfig = {
  playvillService: `${process.env.VUE_APP_PLAYVILL_SERVICE_DEVELOPMENT}`,
};

const environment = getEnvironment();

export const environmentConfig = getEnvObject();

function getEnvironment() {
  let base_url = window.location.origin;
  if (base_url.includes("staging")) return "staging";
  if (base_url.includes("localhost")) return "development";
  else return "production";
}

function getEnvObject() {
  if (environment === "development") return developmentConfig;
  if (environment === "production") return productionConfig;
}

<template>
  <div class="game-feature-container">
    <img :src="imageSrc" :alt="title" class="game-feature" />
  </div>
</template>

<script>
export default {
  props: {
    imageSrc: String,
    title: String,
  },
};
</script>

<style scoped>
.game-feature-container {
  height: 8rem;
  width: 8rem;
  border-radius: 1rem;
}

.game-feature {
  height: 8rem;
  width: 8rem;
  border-radius: 1rem;
}
</style>

<template>
  <div class="home-view-container">
    <div class="display-username">
      <display-username></display-username>
    </div>
    <div class="logo-animation">
      <logo-animation></logo-animation>
    </div>
    <div class="quick-links" ref="quickGamesRef">
      <quick-links></quick-links>
    </div>
    <div class="quick-games-list">
      <quick-games-list :quickLinksHeight="quickLinksHeight"></quick-games-list>
    </div>
  </div>
</template>

<script>
import LogoAnimation from "../components/layout/LogoAnimation.vue";
import QuickLinks from "../components/layout/QuickLinks.vue";
import QuickGamesList from "../components/games/QuickGamesList.vue";
import DisplayUsername from "../components/profile/DisplayUsername.vue";

export default {
  components: {
    LogoAnimation,
    QuickLinks,
    QuickGamesList,
    DisplayUsername,
  },
  data() {
    return {
      quickLinksHeight: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.calculateQuickLinksHeight();
      }, 5); // Adjust the delay as needed
    });
    window.addEventListener("resize", this.calculateQuickLinksHeight); // Recalculate on window resize
    document.body.style.overflow = "hidden"; // Prevent scrolling when component is mounted

    console.log("Store state:", this.$store.state);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calculateQuickLinksHeight); // Clean up
    document.body.style.overflow = ""; // Re-enable scrolling when component is unmounted
  },
  methods: {
    calculateQuickLinksHeight() {
      this.$nextTick(() => {
        const rect = this.$refs.quickGamesRef.getBoundingClientRect();
        this.quickLinksHeight = window.innerHeight - rect.bottom;
      });
    },
  },
};
</script>

<style scoped>
.logo-animation {
  margin-top: 1rem;
}

.quick-links {
  margin-top: 1rem;
}

.quick-games-list {
  margin-top: 1rem;
}

.display-username {
  margin-top: 1rem;
  margin-left: 1rem;
}
</style>

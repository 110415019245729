<template>
  <div class="settings-view-container">
    <div class="logo-animation">
      <logo-animation></logo-animation>
    </div>
    <div class="settings-username">
      <settings-username></settings-username>
    </div>
    <div class="terms-and-conditions-wrapper">
      <button @click="navigateToTerms" class="btn">Terms & Conditions</button>
    </div>
    <div class="manage-subscription-wrapper">
      <button @click="navigateToManageSubscription" class="btn">
        Manage subscription
      </button>
    </div>
    <div class="logout-wrapper">
      <button @click="handleLogout" class="btn">Logout</button>
    </div>
  </div>
  <div class="slogan-wrapper">
    <p class="slogan">Get playing</p>
  </div>
</template>

<script>
import store from "../store";

import LogoAnimation from "../components/layout/LogoAnimation.vue";
import SettingsUsername from "../components/profile/SettingsUsername.vue";

export default {
  components: {
    LogoAnimation,
    SettingsUsername,
  },
  methods: {
    navigateToTerms() {
      this.$router.push({ path: "/terms" }); // Navigate to the /terms route
    },
    navigateToManageSubscription() {
      this.$router.push({ path: "/manage-subscription" });
    },
    async handleLogout() {
      await store.dispatch("logout");

      if (!store.getters.isAuthenticated) {
        this.$router.push({ name: "login" });
        localStorage.clear();
        sessionStorage.clear();
      } else {
        console.log("Authentication failed!");
      }
    },
  },
};
</script>

<style scoped>
.settings-view-container {
  position: relative; /* Ensure this is set */
  min-height: 100vh; /* Optional: Ensures it takes at least the full viewport height */
}

.logo-animation {
  margin-top: 2rem;
}

.settings-username {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.btn {
  border: none;
  background-color: var(--color-text);
  color: var(--color-heading);
  font-family: "Montserrat", sans-serif;
  border-radius: 50rem;
  padding: 0.5rem 2rem;
  width: 15rem;
  margin-top: 1rem;
  font-size: 1rem;
}

.terms-and-conditions-wrapper,
.manage-subscription-wrapper,
.logout-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slogan-wrapper {
  position: absolute;
  bottom: 25%;
  width: 100%;
  text-align: center;
}

.slogan {
  font-style: italic;
  color: var(--color-heading);
}
</style>

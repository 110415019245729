<template>
  <div class="terms-container">
    <h2>PLAYVILL.CO.ZA T&CS</h2>
    <h3>Terms and Conditions</h3>
    <ol>
      <li>
        <span class="title">INTRODUCTION</span>
        <ol>
          <li class="paragraph">
            These terms and conditions are hereinafter referred to as the
            “Agreement”.
          </li>
          <li class="paragraph">
            The Agreement is entered into by you, the user of the Service
            (Hereinafter referred to as “You”) and the provider of the Service,
            being Hulala Holdings (Proprietary) Limited (Hereinafter referred to
            as “Us” and/or the “Company”,)
          </li>
          <li class="paragraph">
            The terms “Your”, “Our”, “We” and any analogous terms shall apply as
            is appropriate under the circumstances herein.
          </li>
          <li class="paragraph">
            IF YOU DO NOT HAVE THE BILL PAYERS’ PERMISSION TO USE THE SERVICE -
            THEN DO NOT ACCESS THE SERVICE AND PROMPTLY CEASE USING THE SERVICE.
          </li>
        </ol>
      </li>
      <div class="spacer"></div>
      <li>
        <span class="title">THE SERVICE</span>
        <ol>
          <li class="paragraph">
            Subscribe to play daily games and stand a chance to win data.
          </li>
          <li class="paragraph">
            When You subscribe to the Service, You can compete against other
            players and stand a chance to win a share of 30GB in data every
            month.
          </li>
        </ol>
      </li>
      <div class="spacer"></div>
      <li>
        <span class="title">HOW TO SUBSCRIBE</span>
        <ol>
          <li class="paragraph">
            To subscribe, You can:
            <ol>
              <li class="sub-paragraph">
                go to www.playvill.co.za and click ‘join now’,
              </li>
              <li class="sub-paragraph">
                dial *686*MTNUSSDSERVICEID# and select ‘subscribe’
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <div class="spacer"></div>
      <li>
        <span class="title">WHAT IT COSTS</span>
        <ol>
          <li class="paragraph">
            The subscription costs R7.00 per day, R35.00 per week or R60.00 per
            month depending on the option you choose (Network charges may also
            apply).
          </li>
          <li class="paragraph">
            The subscription fee will be recovered directly from Your network
            service provider by way of airtime billing and You will, in turn,
            pay Your network service provider. You authorize Us to recover the
            relevant amounts directly from Your network service provider who You
            authorize to pay Us by way of your airtime as soon as such airtime
            is available . You confirm that You are allowed to provide the
            authorizations mentioned above, or that you have obtained the
            billpayers express and informed consent. Network charges may also
            apply.
          </li>
        </ol>
      </li>
      <div class="spacer"></div>
      <li>
        <span class="title">HOW TO WIN A PRIZE</span>
        <ol>
          <li class="paragraph">
            Any person who subscribes the Service and logs in to play will
            qualify for free entry into the random monthly draw.
          </li>
          <li class="paragraph">
            30 subscribers will each win 1GB every month.
          </li>
          <li class="paragraph">
            We will load the data directly onto Your mobile network account.
          </li>
          <li class="paragraph">
            You cannot exchange the data for anything else or transfer it to
            anyone else.
          </li>
          <li class="paragraph">
            Our decision is final and no correspondence shall be entered into in
            connection therewith.
          </li>
        </ol>
      </li>
      <div class="spacer"></div>
      <li>
        <span class="title">HOW TO UNSUBSCRIBE</span>
        <ol>
          <li class="paragraph">
            To unsubscribe, You can dial *155# and select the “unsubscribe”
            prompt.
          </li>
        </ol>
      </li>
      <div class="spacer"></div>
      <li>
        <span class="title">WHO MAY USE THIS SERVICE</span>
        <ol>
          <li class="paragraph">
            To subscribe, You hereby represent and warrant to Us that at all
            such times You:
            <ol>
              <li class="sub-paragraph">are located in South Africa;</li>
              <li class="sub-paragraph">be 18 years or older</li>
              <li class="sub-paragraph">
                be an MTN subscriber (PayAsYouGo, TopUp or Contract)
              </li>
              <li class="sub-paragraph">have data or a wi-fi connection</li>
              <li class="sub-paragraph">
                are of sound mind and capable of taking responsibility for Your
                own actions;
              </li>
              <li class="sub-paragraph">
                understand that You may be charged for using the Service and
                that You accept full responsibility for any such charges that
                may apply;
              </li>
              <li class="sub-paragraph">
                are acting as principal and not on behalf of anyone else;
              </li>
              <li class="sub-paragraph">
                You are the authorized owner of the mobile device which You used
                to subscribe to the Service.
              </li>
            </ol>
          </li>
          <li class="paragraph">
            You agree to abide in full by this Agreement.
          </li>
          <li class="paragraph">
            You hereby warrant to Us that:
            <ol>
              <li class="sub-paragraph">
                any information provided to Us and all personal information (as
                defined in the Protection of Personal Information Act, 4 of
                2013, or otherwise) provided to Us is complete, accurate and not
                misleading, and;
              </li>
              <li class="sub-paragraph">
                You will only use the Service strictly for legitimate and lawful
                purposes only, and;
              </li>
              <li class="sub-paragraph">
                You will not attempt to hack, make unauthorized alterations to
                or introduce any kind of malicious code to any aspect of the
                Service or the Company, by any means whatsoever. Criminal
                charges and civil legal proceedings may be brought against You
                if You try to manipulate the Service or any of the Content, and
                We reserve the right to pass on such information as We deem
                necessary to the relevant authorities if We become aware or
                suspect that You are involved in any such activities. You agree
                to any such disclosure and hold Us harmless in respect thereof.
              </li>
            </ol>
          </li>
          <li class="paragraph">
            You may experience problems or be unable to access certain Content
            successfully depending on the mobile device used to access certain
            Content. If You have an issue that limits Your access to some or all
            of the Content and would like to raise a query or ask for technical
            assistance, please email Our customer support team at
            <a href="mailto:support@zaply.co.za">support@zaply.co.za</a>. You
            acknowledge that We are not responsible for any costs or losses
            incurred by You or loss of data (including Personal Information
            and/or special Personal Information) resulting from any such
            incompatibility.
          </li>
          <li class="paragraph">
            We make no representation as to the compatibility of Your mobile
            device with the Service and You acknowledge and agree that the
            Company shall have no liability for the compatibility or
            non-compatibility of Your mobile device with the Service and/or the
            Content.
          </li>
        </ol>
      </li>
      <div class="spacer"></div>
      <li>
        <span class="title">OUR LIABILITY</span>
        <ol>
          <li class="paragraph">
            The following provisions set out Our entire financial liability
            (including any liability for acts or omissions of Our parent
            company, subsidiaries, associated companies, employees, agents and
            sub-contractors and duly appointed billing partner Worldplay (Pty)
            Ltd) to You and/or any other 3rd parties, in respect of, inter
            alia,:
            <ol>
              <li class="sub-paragraph">any breach of this Agreement, and;</li>
              <li class="sub-paragraph">
                any representation, statement and/or delictual act or omission
                including negligence arising under or in connection with the
                Service.
              </li>
            </ol>
          </li>
          <li class="paragraph">
            Nothing in this Agreement excludes or limits our liability:
            <ol>
              <li class="sub-paragraph">
                for death or personal injury caused by Our gross negligence, or;
              </li>
              <li class="sub-paragraph">
                for any matter which it would be illegal for Us to exclude or
                attempt to exclude liability for, or;
              </li>
              <li class="sub-paragraph">
                for fraud or fraudulent misrepresentation.
              </li>
            </ol>
          </li>
          <li class="paragraph">
            We are not liable for any loss or damage that You may suffer because
            of any act of Force Majeure including, inter alia, any act of God,
            pandemic, power cut, trade or labour dispute, failure or any
            omission of any government or authority; obstruction or failure of
            telecommunication services or any other delay or failure caused by a
            third party or which is outside of Our control. In such an event, We
            reserve the right to cancel or suspend the Service in whole or part
            without incurring any liability.
          </li>
          <li class="paragraph">
            All representations, warranties and terms (whether express or
            implied) not set out in this Agreement are, to the fullest extent
            permitted by law, excluded and We shall, to the fullest extent
            permitted by law, have no liability to You in respect of same. You
            agree to indemnify Us and hold Us harmless in respect of any
            liability, damages, costs or claims (save to the extent the same
            arise out of or in connection with Our breach of this Agreement or
            Our gross negligence) which We may suffer arising out of or in
            connection with Your use of the Service or otherwise arising out of
            or in connection with Our Service. Your statutory rights as a
            consumer (if any) are not affected by this Agreement.
          </li>
          <li class="paragraph">
            Subject to Clause 8.2,
            <ol>
              <li class="sub-paragraph">
                Our liability to You, including for negligence or breach of
                statutory duty, misrepresentation, restitution or otherwise,
                arising in connection with the performance or contemplated
                performance of the Service shall be limited to the aggregate of
                the sum paid for the Service in question for the preceding 12
                (twelve) months;
              </li>
              <li class="sub-paragraph">
                We shall not be liable to You for any indirect or consequential
                loss or any claims for consequential compensation whatsoever
                (howsoever caused) which arise out of or in connection with the
                Services whether or not We have been made aware of it;
              </li>
              <li class="sub-paragraph">
                We shall not be held liable or responsible for any consequences
                that occur through Your use of the Service where the
                circumstances that caused such consequences were beyond Our
                reasonable control, including any loss or damage that has arisen
                through the Content or the Service or its content, including
                delays or interruptions in operation or transmission, loss or
                corruption of data, any person’s misuse of the Service or any
                error or omission in content.
              </li>
            </ol>
          </li>
          <li class="paragraph">
            We accept no responsibility and shall not be liable to You for the
            Content of or use by You of any information or services offered by
            third parties’ advertising (including advertising by any referral
            companies) and/or Our 3rd party providers or otherwise posting
            information (whether directly or via links to or from other sites or
            resources or through framing or other electronic mechanisms), nor
            can We be said to endorse the contents of such advertisements or
            information. In particular, We shall have no liability in respect of
            material hyperlinked to its web pages which may be misleading,
            inaccurate, defamatory, threatening or obscene or otherwise not in
            accordance with applicable laws or regulations. The provision by Us
            of a link to website does not constitute any authorization to access
            materials, nor any accreditation of any such materials held at that
            location.
          </li>
          <li class="paragraph">
            We make no representation or warranty about the information or any
            other items able to be accessed either directly or indirectly via
            the Service (save to the extent expressly provided in the Service)
            and We reserve the right to make changes and corrections at any time
            to such information. We accept no liability for any inaccuracies or
            omissions (other than a fraudulent misrepresentation) in or from
            such information and any decisions based on such information are the
            sole responsibility of the User.
          </li>
          <li class="paragraph">
            We are not liable for any failure to perform by a third party to
            this Agreement.
          </li>
        </ol>
      </li>
      <div class="spacer"></div>
      <li>
        <span class="title">HOW TO KEEP IT CLEAN</span>
        <ol>
          <li class="paragraph">
            Don’t do anything shady with this Service, including but not limited
            to;
            <ol>
              <li class="sub-paragraph">
                do not intentionally engage in illegal conduct
              </li>
              <li class="sub-paragraph">
                do not knowingly create, store, or disseminate any illegal
                content
              </li>
              <li class="sub-paragraph">do not knowingly infringe copyright</li>
              <li class="sub-paragraph">
                do not knowingly infringe any intellectual property rights
              </li>
              <li class="sub-paragraph">
                do not send spam or promote the sending of spam
              </li>
            </ol>
          </li>
          <li class="paragraph">
            We may take down any content that We consider illegal, offensive, or
            for which We receive a take-down notice. Yes, if you choose an
            offensive username, we’ll change it.
          </li>
        </ol>
      </li>
      <div class="spacer"></div>
      <li>
        <span class="title">THE SERVICE MAY END</span>
        <ol>
          <li class="paragraph">
            We may suspend our Services temporarily or permanently discontinue
            them at any time.
          </li>
          <li class="paragraph">
            We may stop You from using our Service if
            <ol>
              <li class="sub-paragraph">
                Your subscription fee is not paid on time, or
              </li>
              <li class="sub-paragraph">
                You break the rules in this Agreement.
              </li>
            </ol>
          </li>
          <li class="paragraph">
            We reserve the right to monitor the use of the Service and We may
            elect, in Our sole discretion and without providing reasons
            therefor, to suspend and/or terminate Your subscription and/or use
            of the Service, if We consider or suspect that the Service or any
            parts thereof is being used in breach of this Agreement or for any
            other reason that We deem necessary.
          </li>
          <li class="paragraph">
            We are not responsible for any losses or damages You may suffer
            because Your subscription is temporarily suspended and/or
            permanently discontinued for whatsoever reason.
          </li>
        </ol>
      </li>
      <div class="spacer"></div>
      <li>
        <span class="title">THE RULES MAY CHANGE</span>
        <ol>
          <li class="paragraph">
            We may need to change these rules from time to time, however we will
            endeavour to let you know by SMS.
          </li>
          <li class="paragraph">
            If you continue to use our Service after the rules change, You shall
            be deemed to have unconditionally and irrevocably accepted the
            changes, notwithstanding that You may not have received SMS
            notification thereof.
          </li>
        </ol>
      </li>
      <div class="spacer"></div>
      <li>
        <span class="title">WE OWN THE CONTENT</span>
        <ol>
          <li class="paragraph">
            The copyright, database rights and other intellectual property
            rights (“IPR”) in material displayed on or via the Service
            (collectively the 'Materials', which expression includes, inter
            alia, text, data, graphics, photographs, videos, animation, images
            and audio-visual content, are owned by, or licensed to, Us or are
            the ownership of third party websites). The IPR are protected by the
            laws of South Africa, international treaties and all other
            applicable copyright and intellectual property rights laws. You are
            not authorized to copy or distribute any Materials and/or IPR and
            legal action could be taken against You or any such person who makes
            unauthorized copies or distribution of Materials and/or IPR.
          </li>
          <li class="paragraph">
            Any downloading, use or copying of the Materials is strictly
            prohibited and, in particular, You agree to use the Materials solely
            for Your own personal, non-commercial use and specifically not for
            any business, commercial or public purposes or for any purposes
            which may be otherwise unlawful.
          </li>
          <li class="paragraph">
            The following activities are prohibited without Our express prior
            written permission:
            <ol>
              <li class="sub-paragraph">
                the deployment within the Service of any spider, robot, web
                crawler or other automated query program; and
              </li>
              <li class="sub-paragraph">
                the re-use and/or aggregation of any of the Materials in the
                provision of a commercial service.
              </li>
            </ol>
          </li>
          <li class="paragraph">
            The copying and use of third party Materials accessed via the
            Service is governed by the terms of use applicable to the
            third-party website accessed by You.
          </li>
          <li class="paragraph">
            Our names and associated logos together with those of our 3rd party
            providers, where applicable, are our exclusive trademarks and cannot
            be used by You without Our prior written permission.
          </li>
        </ol>
      </li>
      <div class="spacer"></div>
      <li>
        <span class="title">WASPA</span>
        <ol>
          <li class="paragraph">
            Worldplay (Pty) Ltd, our duly authorised billing partner, is a
            member of the Wireless Application Service Providers Association
            (“WASPA”) and is bound by the WASPA Code of Conduct available at
            www.waspa.org.za.
          </li>
          <li class="paragraph">
            You may lodge a complaint with WASPA by following the WASPA
            complaints procedure available at www.waspa.org.za.
          </li>
          <li class="paragraph">
            We may need to share information about You or the Service forming
            the subject of Your complaint with WASPA to help resolve the
            complaint.
          </li>
        </ol>
      </li>
      <div class="spacer"></div>
      <li>
        <span class="title">HOW YOU CAN REACH US</span>
        <ol>
          <li class="paragraph">
            If you have any questions about billing or anything else, you can
            reach us at:
            <a href="mailto:support@zaply.co.za">support@zaply.co.za</a>
          </li>
        </ol>
      </li>
      <div class="spacer"></div>
      <li>
        <span class="title">GENERAL</span>
        <ol>
          <li class="paragraph">
            This Agreement constitutes to the fullest extent permitted by law
            the whole of the Agreement between You and Us with regard to the use
            of the Service.
          </li>
          <li class="paragraph">
            If any part of this Agreement is found by a court of competent
            jurisdiction or other competent authority to be invalid, unlawful or
            unenforceable then any such part will be severed from the remainder
            of this Agreement, which will continue to be valid and enforceable
            to the fullest extent permitted by law.
          </li>
          <li class="paragraph">
            No failure or delay by Us to exercise any of Our rights under this
            Agreement shall operate as a waiver thereof and no single or partial
            exercise of any such right shall prevent any other or further
            exercise of that or any other right by Us.
          </li>
          <li class="paragraph">
            This Agreement shall be governed by and construed in accordance with
            the laws of the Republic of South Africa. Subject to clause 13, You
            irrevocably agree that the courts of the Republic of South Africa
            shall have exclusive jurisdiction to resolve any dispute or claim of
            whatever nature arising out of or relating to the Service, and that
            the laws of the Republic of South Africa shall govern any such
            dispute or claim. However, we retain the right to bring legal
            proceedings in any jurisdiction where We believe that infringement
            of our intellectual property rights or breach of this Agreement is
            taking place or originating. You are responsible for compliance with
            any applicable laws and regulations of the jurisdiction from which
            you are accessing or using the Service, whether in whole or part.
          </li>
        </ol>
      </li>
    </ol>
  </div>
</template>

<style scoped>
.terms-container {
  margin: 0rem 0rem 0rem 0rem;
  padding: 1rem 1rem 5rem 1rem;
  color: var(--color-heading);
  background-color: var(--vt-c-blue-primary);
}

ol {
  counter-reset: section;
  list-style-type: none;
  padding: 0;
}

ol > li {
  counter-increment: section;
}

ol > li:before {
  content: counters(section, ".") ". ";
}

ol > li > ol {
  counter-reset: subsection;
}

ol > li > ol > li {
  counter-increment: subsection;
  padding-left: 1rem;
}

ol > li > ol > li:before {
  content: counters(section, ".") "." counters(subsection, ".") " ";
}

.title {
  font-weight: 800;
  margin-top: 2rem;
}

.paragraph {
  margin-top: 0.8rem;
  font-size: 0.8rem;
}

.spacer {
  width: 100%;
  height: 2rem;
}

.sub-paragraph {
  margin-top: 0.4rem;
}
</style>

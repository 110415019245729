// store.js
import { createStore } from "vuex";

export default createStore({
  state: {
    isAuthenticated: false,
    msisdn: "",
    username: "",
  },
  mutations: {
    login(state) {
      state.isAuthenticated = true;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.msisdn = "";
      state.msisdn = "";
    },
    setMsisdn(state, msisdn) {
      state.msisdn = msisdn;
    },
    setUsername(state, username) {
      state.username = username;
    },
  },
  actions: {
    login({ commit }, payload) {
      commit("login");
      commit("setMsisdn", payload.phoneNumber);
      commit("setUsername", payload.username);
    },
    logout({ commit }) {
      commit("logout");
    },
    updateUsername({ commit }, username) {
      commit("setUsername", username);
      // Here you might also want to send the updated username to the server
    },
  },
  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
    msisdn: (state) => state.msisdn,
    username: (state) => state.username,
  },
});
